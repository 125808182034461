
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class candidateService {


    headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
    public userData: any;


    constructor(private http: HttpClient) {
        let user = localStorage.getItem('userDetails');
        if (user != null) {
            // let userDetails = JSON.parse(user);
            // this.userData = userDetails;
            // let userId = userDetails.userId.toString();
            // this.headers = this.headers.append('userId', userId);

            this.userData = jwtDecode(user)
            let userId = this.userData.UserId
            this.headers = this.headers.append('userId', userId);
        }
    }

    createCandidate(params) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidate`, params, { headers: this.headers })

    }

    viewCandidate(id) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetAllCandidateInfo?CandidateId=${id}`, { headers: this.headers });
    }

    getStagedata(isManagesettings = false) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewStages?isManagesettings=${isManagesettings}`, { headers: this.headers });
    }
    getStageType() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetMaster_StageValue`, { headers: this.headers });
    }

    GetStatus(isManagesettings = false) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetStatus?isManagesettings=${isManagesettings}`, { headers: this.headers });
    }

    getHiringData() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStage`, { headers: this.headers });
    }

    getCandidateList(pageNo, pageSize, status, userId) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetCandidateInfo?pageNumber=${pageNo}&numberOfObjectsPerPage=${pageSize}&status=${status}&userId=${userId}`, { headers: this.headers });
    }

    getJobTitleData() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetJobTitle`, { headers: this.headers })
    }

    updateComment(params) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/UpdateComments`, params, { headers: this.headers })
    }

    insertBookmark(userId, candidateId, status) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/CreateCandidateBookmark?candidateId=${candidateId}&bookmarkStatus=${status}&userId=${userId}`, { headers: this.headers })
    }

    candidateSearchFilter(pageNo, status, numberofobjectperpage, jobId) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetCandidatesbyJobsearch?pageNumber=${pageNo}&status=${status}&jobName=${jobId}&numberOfObjectsPerPage=${numberofobjectperpage}`, { headers: this.headers })
    }


    getJobReassign() {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetJobAssign`, { headers: this.headers })
    }

    reassignsubmit(params) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/JobAssignforCandidate`, params, { headers: this.headers })
    }

    sendMessage(params) {
        return this.http.post(`${environment.apiUrl}/api/Message/SendMessage`, params, { headers: this.headers })
    }

    createSchedule(params) {
        return this.http.post(`${environment.apiUrl}/api/Message/SendSchedule`, params, { headers: this.headers })
    }

    getUserPermission() {
        return this.userData;
    }
    getCandidateProfile(id) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetCandidateListbyId?id=${id}`, { headers: this.headers });
    }

    createCv(data) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/ShareCv`, data, { headers: this.headers })
    }

    validateCandidatePhoneNumber(phoneNo) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateCandidatePhoneNo?phoneNo=${phoneNo}`, { headers: this.headers })
    }
    getScheduleCandidateList() {
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetCandidateList`, { headers: this.headers })
    }
    getSchedulesByDatefilter(from, end) {
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetSchedulesByDatefilter?fromdate=${from}&enddate=${end}`, { headers: this.headers })
    }
    getSchedulesByID(schId) {
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetSchedulebyId?scheduleId=${schId}`, { headers: this.headers })
    }
    deleteSchedule(id) {
        return this.http.get(`${environment.apiUrl}/api/Message/DeleteSchedule?scheduleId=${id}`, { headers: this.headers })
    }
    createCandidateFilter(data) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/GetCandidatesbyFilter`, data, { headers: this.headers })
    }
    createScheduleFilter(data) {
        return this.http.post(`${environment.apiUrl}/api/Dashboard/GetSchedulesfilter`, data, { headers: this.headers })
    }
    createFilterData(data) {
        // /api /Candidate /GetCandidatesBookmarkFilter
        return this.http.post(`${environment.apiUrl}/api/Candidate/GetCandidatesBookmarkFilter`, data, { headers: this.headers })
    }
    searchInterview(data) {
        // /api/Dashboard/GetSchedulesByJobtitle?jobTitle=QA
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetSchedulesByJobtitle?jobTitle=${data}`, { headers: this.headers })
    }
    CreateCandidateDetails(data) {
        // /api /Candidate /GetCandidatesBookmarkFilter
        return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidateDetails`, data, { headers: this.headers })
    }
    GetCandidateCountryList() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetCandidateCountryList`, { headers: this.headers });
    }

    GetCandidateCountryListById(data) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetCandidateCountryListById?id=${data}`, { headers: this.headers });
    }
    InsertCandidateBookmarks(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/InsertApplicantBookmarks`, data, { headers: this.headers });
    }

    extractData(data, file) {
        // var settings = {
        //     "url": "http://18.141.11.68/parse?extract_fields=true",
        //     "method": "POST",
        //     "timeout": 0,
        //     "processData": false,
        //     "mimeType": "multipart/form-data",
        //     "contentType": false
        //   };
        //   let headers =  {
        //     "Content-Type": "application/json",
        //     "Authorization": "Basic YWRtaW46YWRtaW4="
        //   };
        // $.ajax(settings).done(function (response) {
        //     return response
        // })
        $.ajax({
            url: 'http://54.254.113.224/parse?extract_fields=true',
            headers: {
                'Authorization': 'Basic YWRtaW46YWRtaW4='
            },

            type: 'POST',
            data: file,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            success: function (response) {

                console.log(response);
                this.setData(response);
                this.fileData = response;
            }
        });

        //  return this.fileData ;


        //   var fileheaders = new HttpHeaders().set('contentType', 'false').set('Access-Control-Allow-origin', '*')
        //   .set('Cache-Control', 'no-cache').set('Authorization','Basic YWRtaW46YWRtaW4=').set('enctype','multipart/form-data');
        // return this.http.post(`http://18.141.11.68/parse?extract_fields=true`, file, { headers: fileheaders } )
    }
    cleanPdf() {
        var fileheaders = new HttpHeaders().set('Content-Type', 'multipart/form-data').set('Access-Control-Allow-origin', '*')
            .set('Cache-Control', 'no-cache').set('Authorization', 'Basic YWRtaW46YWRtaW4=');
        return this.http.post(`http://54.254.113.224/clean`, {}, { headers: fileheaders })

    }

    getBulkData() {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetResumeData`, { headers: this.headers })
    }

    createBulk(data) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/UploadResumes`, data, { headers: this.headers })

    }
    BulkUploadApplicants(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/BulkUploadApplicants`, data, { headers: this.headers })

    }

    //

    messageDelete(data) {
        // api/Message/DeleteMessage?messageId=33
        return this.http.get(`${environment.apiUrl}/api/Message/DeleteMessage?messageId=${data}`, { headers: this.headers })

    }

    deleteDoc(id) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/DeleteEducationDocument?documentId=${id}`, { headers: this.headers })
    }
    getjobdata() {
        return this.http.get(`${environment.apiUrl}/api/Jobs/GetPublishedJobDetails`, { headers: this.headers });
    }
    GetCandidateList() {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetCandidateList`, { headers: this.headers });
    }
    UserTableOptions(data) {
        return this.http.post(`${environment.apiUrl}/api/Jobs/AddCareerUserTableOptions`, data, { headers: this.headers });
    }
    public editJobInfoGetById(id: any) {
        return this.http.get(`${environment.apiUrl}/api/CareerApi/GetJobDetailsById?jobID=${id}`, { headers: this.headers })
    }
    public getCareerUserTable(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Jobs/GetCareerUserTableOptions?userid=${id}`, { headers: this.headers })
    }
    getLevelOfEducation(isManagesettings = false) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetEducationList?isManagesettings=${isManagesettings}`, { headers: this.headers });
    }
    getDegree(isManagesettings = false) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetDegree?isManagesettings=${isManagesettings}`, { headers: this.headers })
    }

    GetCategoryType(isManagesettings = false) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetCategoryType?status=${isManagesettings}`, { headers: this.headers });
    }
    GetSkillNameById(data) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetSkillNameById?id=${data}`, { headers: this.headers });
    }
    getLanguages(isManagesettings = false) {
        //  return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages`, { headers: this.headers });
        return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages?isManagesettings=${isManagesettings}`, { headers: this.headers });
    }
    GetWorkPermitType() {
        //  return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages`, { headers: this.headers });
        return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitType`, { headers: this.headers });
    }
    GetDocumentRelated(data) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentRelated?Documenttype=${data}`, { headers: this.headers });
    }
    public GetAllCandidatesById(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetAllCandidatesById?CandidateId=${id}`, { headers: this.headers })
    }
    GetCandidateFilterNames() {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetCandidateFilterNames`, { headers: this.headers });
    }

    getApplicantInfo(i: any) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetAllCandidatesById?CandidateId=${i}`, { headers: this.headers });
    }

    getEnquiryPaginationInfo(pagePerPageSize: any, pageNo : any, status:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantResumeData?PagesizePerPage=${pagePerPageSize}&pageNumber=${pageNo}&Status=${status}`, { headers: this.headers });
    }

    applicantResumeDataSearch(eSearch:any,pagePerPageSize: any, pageNo : any, status:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ApplicantResumeDataSearch?searchitem=${eSearch}&PagesizePerPage=${pagePerPageSize}&pageNumber=${pageNo}&Status=${status}`, { headers: this.headers });
    }

    ApplicantsSearch(searchItem:any,pagePerPageSize: any, pageNo : any,currentStage:any, userId:any) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ApplicantsSearch?Searchitem=${searchItem}&PagesizePerPage=${pagePerPageSize}&PageNumber=${pageNo}&StageName=${currentStage}&userId=${userId}`, { headers: this.headers });
    }
    //  http://18.142.45.251:8080/giis-career-dev-dev/career/getCountries

    CreateApplicantPersonalDetails(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantPersonalDetails1`, data, { headers: this.headers })

    }
    // //api/Applicant/GetStatesPhp?CountryId=

    getCoucampus(status:any) {
      return this.http.get(`${environment.apiUrl}/api/Applicant/GetStatesPhp?CountryId=${status}`, { headers: this.headers });
  }

    // getCoucampus(val: any) {
    //     var valnew = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    //         .set('Pragma', 'no-cache').set('CountryId', '' + val);

    //     return this.http.get(`https://gsf-career-api-dev.irepo.in:8443/giis-career-dev/career/getStates`, { headers: valnew });
    // }

    getCountriesnew() {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetCountriesPhp`, { headers: this.headers });
    }

    GetDomain() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetDomain`, { headers: this.headers });
    }

    GetIndustry() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetIndustry`, { headers: this.headers });
    }

    CreateApplicantWorkHistory(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantWorkHistory`, data, { headers: this.headers })

    }

    GetApplicantDocumentById(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantDocumentById?Id=${id}`, { headers: this.headers })
    }


    CreateApplicantEducationDetails(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantEducationDetails`, data, { headers: this.headers })

    }
    CreateApplicantImmigration(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantImmigration`, data, { headers: this.headers })

    }

    // getEthicReligionList() {
    //     return this.http.get(`https://gsf-career-api-dev.irepo.in:8443/giis-career-dev/career/getEthicReligionList`, { headers: this.headers });

    // }

    GetDegreenew() {
      return this.http.get(`${environment.apiUrl}/api/Master/GetDegree?isManagesettings=true`, { headers: this.headers });
  }

  getEthicReligionList() {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetEthicReligionListPhp`, { headers: this.headers });
    }




    GetSkills_Category() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetSkills_Category`, { headers: this.headers });
    }
    // getskillOncategory

    getskillOncategory() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetCategoryType?status=true`, { headers: this.headers });
    }

    // http://18.142.45.251/DevGIISCareerApi/api/Candidate/GetYears
    GetYears() {
        return this.http.get(`${environment.apiUrl}/api/Candidate/GetYears`, { headers: this.headers });
    }


    GetDocumentByNationalityId() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentByNationalityId`, { headers: this.headers });
    }
    GetNationalityList() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityList`, { headers: this.headers });
    }
    GetNationalityById(id:any) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityById?id=${id}`, { headers: this.headers });
    }

    GetJobDocumentTypeDocumentNameDetails(val: any) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetJobDocumentTypeDocumentNameDetails?jobId=${val}`, { headers: this.headers });
    }


    createApplicantDocument(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/createApplicantDocument`, data, { headers: this.headers })

    }

    GetYearsDetails() {
        return this.http.get(`${environment.apiUrl}/api/Master/GetYearsDetails`, { headers: this.headers });
    }



    // api/Applicant/GetWorkPermitTypeByJobId?id=2

    // getApplicantList(userId) {
    //     return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicants?userId=${userId}`, { headers: this.headers });
    // }
    intApplicantList(userId,PagesizePerPage,pageNumber,stagename) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicants?userId=${userId}&PagesizePerPage=${PagesizePerPage}&pageNumber=${pageNumber}&stagename=${stagename}`, { headers: this.headers });
    }

    getApplicantList(userId,PagesizePerPage,pageNumber,stagename) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicants?userId=${userId}&PagesizePerPage=${PagesizePerPage}&pageNumber=${pageNumber}&stagename=${stagename}`, { headers: this.headers });
    }

    GetWorkPermitTypeByJobId(val: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetWorkPermitTypeByJobId?id=${val}`, { headers: this.headers });
    }

    getcities(val: any) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetCitiesBasedOnCountryId?countryId=${val}`, { headers: this.headers });
    }


    getApplicantByData(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantDetailsById?id=${id}`, { headers: this.headers });
    }

    // getapplicantdetails(val: any) {
    //     var valnew = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    //         .set('Pragma', 'no-cache').set('applicantId', '' + val);

    //     return this.http.get(`https://gsf-career-api-dev.irepo.in:8443/giis-career-dev-dev/applicants/getapplicantdetails`, { headers: valnew });
    // }

    ChangeApplicantStatusSubmit(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ChangeApplicantStatusSubmit?applicantId=${id}`, { headers: this.headers });
    }

    getInterveiwDeatils(userId: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetInterviewerUserDetails?userId=${userId}`, { headers: this.headers });
    }

    GetHiringStageHiringMember(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetStatusByHiringStage?id=${id}`, { headers: this.headers });
    }

    GetHiringStageHiringMembernew(id: any) {
      return this.http.get(`${environment.apiUrl}/api/Jobs/GetHiringStageHiringMember?id=${id}`, { headers: this.headers });
  }

    GetApplicantStageInfoById(id: any,sId: any,jId: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantStageInfoById?ApplicantId=${id}&StageId=${sId}&JobId=${jId}`, { headers: this.headers });
    }


    applicantsave(obj) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/getapplicantsave`, obj, { headers: this.headers });
    }
    saveComments(obj) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ManageComments`, obj, { headers: this.headers });
    }
    activityThreads(obj) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ManageActivitiesPhp`, obj, { headers: this.headers });
    }
    confirmAvailability(obj) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ConfirmAvailabilityByHrPhp`, obj, { headers: this.headers });
    }
    GetJobCampus(id) {
        return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobCampus?jobId=${id}`, { headers: this.headers });
    }

    selectDocumnentGet(id) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetJobDocumentTypeDocumentNameDetails?jobId=${id}`, { headers: this.headers })
    }

    bulkUpdate(obj) {
        return this.http.post(`https://gsf-career-api-dev.irepo.in:8443/giis-career-dev/applicants/bulkupdate`, obj, { headers: this.headers });
    }
    ValidateApplicantPhoneNo(number) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateApplicantPhoneNo?phoneNo=${number}`, { headers: this.headers });
    }

    ValidateApplicantEmailId(email) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateApplicantEmailId?mailId=${email}`, { headers: this.headers });
    }
    // createSlot(params: any) {
    //     var valnew = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    //         .set('jobId', '' + params.jobId).set('stageId', '' + params.stageId).set('interviewDate', '' + params.interviewDate);
    //     return this.http.get(`https://gsf-career-api-dev.irepo.in:8443/giis-career-dev-dev/timeslots/gettimeslots`, { headers: valnew });
    // }


    createSlot(params: any){
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetTimeSlotsNet?jobId=${params.jobId}&stageId=${params.stageId}&interviewDate=${params.interviewDate}&applicantId=${params.applicantId}`, { headers: this.headers });
    }

    GetSubStatusById(stageId, statusId) {
        return this.http.get(`${environment.apiUrl}/api/Master/GetSubStatusById?stageId=${stageId}&statusId=${statusId}`, { headers: this.headers });
    }
    GetApplicantDocumentInfo(appid, jobid, stageid) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantDocumentInfo?appid=${appid}&jobid=${jobid}&stageid=${stageid}`, { headers: this.headers });
    }

    GetSlotCountInApplicant(jobid: any,stageid:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetSlotCountInApplicant?jobId=${jobid}&stageId=${stageid}`, { headers: this.headers });
    }

    GetApplicantFilterNames(userId:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterNames?userId=${userId}`, { headers: this.headers });
    }

    GetApplicantResumeData(pagePerPageSize:any,pageNo:any, status:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantResumeData?PagesizePerPage=${pagePerPageSize}&pageNumber=${pageNo}&Status=${status}`, { headers: this.headers });
    }

    GetApplicantResumeDataByID(id:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantResumeDataById?id=${id}`, { headers: this.headers });
    }
    GetApplicantsSearch(obj) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ApplicantsSearch`, { headers: this.headers });
    }


    UploadApplicantResume(obj: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/Applicant/UploadApplicantResume`, obj, { headers: this.headers })
    }

    ValidateWebsiteApplicantResumeEmail(mailId: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateWebsiteApplicantResumeEmailPhoneNo?mailId=${mailId}`, { headers: this.headers });
      }

      ValidateWebsiteApplicantResumePhoneNo(phoneNo: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateWebsiteApplicantResumeEmailPhoneNo?phoneNo=${phoneNo}`, { headers: this.headers });
      }

      interviewPendingSlots(userId:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetScheduleReScheduleData?UserId=${userId}`, { headers: this.headers });
      }

      ConfirmPendingSlots(appid, stageid, slotId ) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/ConfirmPendingSlots?applicantId=${appid}&StageId=${stageid}&SlotId=${slotId}`, { headers: this.headers });
      }

    //   https://gsf-career-api-dev.irepo.in/api/Applicant/ConfirmPendingSlots?applicantId=346&StageId=5&SlotId=510
    ApplicantResumeDataFilter(obj: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ApplicantResumeDataFilter1`, obj, { headers: this.headers })
    }
    GetAllCountryDetails(){
      return this.http.get(`${environment.apiUrl}/api/Master/GetAllCountryDetails`, { headers: this.headers });
    }
    GetApplicantCompletedStageInfo(id:any){
      return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantCompletedStageInfo?ApplicantId=${id}`, { headers: this.headers });
    }
    manageFeedback(obj: any): Observable<any> {
      return this.http.post(`${environment.apiUrl}/api/Applicant/ManageFeedback`, obj, { headers: this.headers })
    }
    GetDocumentStageDetailsByJobId(id:any,appId:any): Observable<any> {
      return this.http.get(`${environment.apiUrl}/api/Jobs/GetDocumentStageDetailsByJobId?JobId=${id}&ApplicantId=${appId}`, { headers: this.headers });
    }
    CreateApplicantStageDocuments(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantStageDocuments`, data, { headers: this.headers })
    }
    CreateRequestApplicantStageDocuments(data) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/CreateRequestApplicantStageDocuments`, data, { headers: this.headers })
    }
    GetApplicantPoolCount() {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantPoolCount`, { headers: this.headers })
    }
    getAppHireBrandDetailsByUserId(userId:any){
      return this.http.get(`${environment.apiUrl}/api/Jobs/GetBrandDetailsByUserId?id=${userId}`, { headers : this.headers });
    }

    getAppHireCountryCAmpusByBrandId(brandId : any){
      return this.http.get(`${environment.apiUrl}/api/Master/GetCountryCampusByBrandId?id=${brandId}`, { headers : this.headers });
    }

    getAppHireDepartments(){
      return this.http.get(`${environment.apiUrl}/api/Master/GetDepartment`, { headers : this.headers });
    }

    getAppHireLevels(){
      return this.http.get(`${environment.apiUrl}/api/Master/GetJobsLevelsData`, { headers : this.headers });
    }

    ValidateTrApproverLevel(campusId : any ,DepartmentId:any, level:any, budget:any){
      return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateTrApproverLevel?CampusId=${campusId}&DepartmentId=${DepartmentId}&Level=${level}&BudOrNonBud=${budget}`, { headers : this.headers });
    }

    ValidateTrApproverCampusFetchJobTitle(brandId:any, campusId : any, depId:any){
        return this.http.get(`${environment.apiUrl}/api/Candidate/ValidateTrApproverCampusFetchJobTitle?brandId=${brandId}&campusId=${campusId}&departmentId=${depId}`, { headers : this.headers });
    }

    getHrUsersByCampusId(campusUserId:any){
        return this.http.get(`${environment.apiUrl}/api/User/getHrUsersByCampusId?CampusId=${campusUserId}`, { headers : this.headers });
    }

    SendEmailOnPerformanceFeedback(hrUserId:any,appId:any,cId:any){
        return this.http.get(`${environment.apiUrl}/api/Applicant/SendEmailOnPerformanceFeedback?userId=${hrUserId}&applicantId=${appId}&campusId=${cId}`, { headers : this.headers });
    }

    ijpApplicantList(UserId:any){
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetPerformanceFeedbackIJPApplicantList?UserId=${UserId}`, { headers : this.headers });
    }

    GetPerformanceFeedbackByApplicantId(id: any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetPerformanceFeedbackByApplicantId?ApplicantId=${id}`, { headers: this.headers });
    }

    addPerformanceFeedback(obj: any) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/AddPerformanceFeedbackStageQuestionDetails`,obj, { headers: this.headers });
    }

    getPerformanceFeedbackId(appId: any,sId:any,jId:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetPerformanceFeedbackTemplateDocById?ApplicantId=${appId}&StageId=${sId}&JobId=${jId}`, { headers: this.headers });
    }

    SendEmailOnApprovalStage(obj: any) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/SendEmailOnApprovalStage`,obj, { headers: this.headers });
    }

    getApprovalStageIJPApplicantList(UserId:any){
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApprovalStageIJPApplicantList?UserId=${UserId}`, { headers : this.headers });
    }

    approvalStageDeny(obj: any) {
        return this.http.post(`${environment.apiUrl}/api/Applicant/ApprovalStageDeny`,obj, { headers: this.headers });
    }

    GetApplicantFilterJobTitle(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterJobTitle?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }

    GetApplicantFilterSource(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterSource?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }

    GetApplicantFilterLocation(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterLocation?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }

    GetApplicantFilterJobScore(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterJobScore?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }

    GetApplicantFilterStage(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterStage?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }

    GetApplicantFilterJobStatus() {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterJobStatus`, { headers: this.headers });
    }


    ///https://gsf-career-api-dev.irepo.in/api/Applicant/GetIJPApplicants?userId=482&PagesizePerPage=10&pageNumber=0&stagename=all

    GetIJPApplicants(userId,PagesizePerPage,pageNumber,stagename) {
      return this.http.get(`${environment.apiUrl}/api/Applicant/GetIJPApplicants?userId=${userId}&PagesizePerPage=${PagesizePerPage}&pageNumber=${pageNumber}&stagename=${stagename}`, { headers: this.headers });
  }

    GetApplicantFilterSchools(userId:any,stagename:any) {
        return this.http.get(`${environment.apiUrl}/api/Applicant/GetApplicantFilterBrand?userId=${userId}&stagename=${stagename}`, { headers: this.headers });
    }
}


// createGeneralInfo(data){
//     return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidateGeneralInfo`,data,{headers:this.headers});
// }

// createWorkInfo(data){
//     return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidateWorkHistoryInfo`,data,{headers:this.headers});
// }

// createEducationInfo(data){
//     return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidateEducationInfo`,data,{headers:this.headers});
// }

// createFamilyOtherInfo(data){
//     return this.http.post(`${environment.apiUrl}/api/Candidate/CreateCandidateFamilyInfo`,data,{headers:this.headers});
// }
