import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IJPService } from 'src/app/services/IJP.service';
import { jwtDecode } from 'jwt-decode';
import { MessageService } from 'primeng/api';
import * as QRCode from 'qrcode';
import * as moment from 'moment';
import { Router } from '@angular/router';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-ijp-employee',
  templateUrl: './ijp-employee.component.html',
  styleUrls: ['./ijp-employee.component.scss'],
  providers: [MessageService],
})
export class IjpEmployeeComponent {
  first: number = 0;



  searchItem: any;
  ijpJobStatus: any;
  pageNO: number = 0;
  dataSource: any[] = [];
  filteredData: any[] = [];
  arraymy: any;
  managePublishStartDate: any;
  managePublishEndDate: any;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private IJPService: IJPService,
    private messageService: MessageService
  ) {}
  UserId: any;
  obj: any = {};
  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.data = jwtDecode(this.token);
    this.UserId = this.data.UserId;
    console.log('this.UserId', this.UserId);

    // this.obj = { page: 0, rows: 10, first: 10 };
    // this.onPageChange(this.obj);

    // console.log(this.data, '=====================');
    this.getListDetails('Active');

  }
  onPageChange(event: PageEvent) {
    this.first = event.first;
    // console.log('0000000000000', this.first);

    this.rows = event.rows;
    // console.log('event', event);
    this.selectedPage = event.page;
    this.getListDetails(this.selectedTab);
  }
  selectedJobsArray : any = [];
  redirectedToDetailsPage(ijpBasicInformationId: any, index:number) {
    // window.location.href = '/job-details-page/' + ijpBasicInformationId;
    this.router.navigateByUrl('/job-details-overview/' + ijpBasicInformationId);
    const selectedJob = this.jobLists.splice(index, 1)[0];
    this.selectedJobsArray.push(selectedJob);
    // console.log(this.selectedJobsArray[0].isIjpEmployee, "nnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.setItem('isIjpEmployee', JSON.stringify(this.selectedJobsArray[0].isIjpEmployee));
  }

  requarray: any[] = [];

  // jobLists :any[] = [
  //   {
  //     brand_IJPJobId : 'Job Id',
  //     jobTitleName : 'Job Id',
  //     campusName : 'Job Id',
  //     categoryName : 'Job Id',
  //     typeOfEmployementName : 'Job Id',
  //     ijpJobCreatedDate : 'Job Id',
  //     cutOffDate : 'Job Id',
  //     applications : 10,

  //   }
  // ];

  toggleKebabMenu(row: any) {
    row.showKebabMenu = !row.showKebabMenu;
  }
  hideKebabMenu(row: any) {
    row.showKebabMenu = false;
  }
  GetIJPJobRecruitmentMembersPopUp(IJPBasicInformationId: any) {
    this.IJPService.GetIJPJobRecruitmentMembersPopUp(
      IJPBasicInformationId
    ).subscribe((res: any) => {
      this.requarray = res.hiringStageHiringName;
      // console.log("GetIJPJobRecruitmentMembersPopUp",res);
    });
  }
  GetIJPJobApproversPopUpData(IJPBasicInformationId: any) {
    this.IJPService.GetIJPJobApproversPopUpData(
      IJPBasicInformationId
    ).subscribe((res: any) => {
      // this.getListDetails('IJPBasicInformationId')
      this.arraymy = res.iJPJobApproverInfos;
      console.log(res, 'GetIJPJobApproversPopUpData');
    });
  }
  commaSeperated(data: any) {
    if (data) {
      var nameArr = data.split(',');
      var obj = nameArr;
      return obj;
    }
  }
  // openCopyModal(row:any){
  //   row.showKebabMenu = false;
  // }

  applySearchFilter(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchValue = inputElement.value.toLowerCase();

    // Implement your filter logic here
    this.filteredData = this.dataSource.filter((item) =>
      item.name.toLowerCase().includes(searchValue)
    );
  }


  addapplicant(val: any) {
    this.router.navigateByUrl('apply-behalf/' + val);


  }

  copyJobTitle: any;
  copyJobID: any;
  ijpJobPostedData: any = [];
  jobInfo: any = [];

  openCopyModal(row: any, jobTitle: any, jobID: any) {
    row.showKebabMenu = false;
    this.ijpJobPostedData = [];
    for (let i = 0; i < row.ijpJobPostedData.length; i++) {
      this.ijpJobPostedData.push(row.ijpJobPostedData[i]);
    }
    this.generateQrCodes();
    this.copyJobTitle = jobTitle;
    this.copyJobID = jobID;
  }

  copyText(inputElement: HTMLInputElement): void {
    console.log('Button clicked!'); // Log when the button is clicked

    if (!inputElement.value) {
      console.warn('Input is empty!');
      return;
    }

    inputElement.select();
    inputElement.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard
      .writeText(inputElement.value)
      .then(() => {
        console.log('Text copied: ', inputElement.value); // Log the copied text
        // alert('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Link Copied Successfully',
    });
  }
  type: string = 'IJP';
  ijpreferral(type: any) {
    this.type = type;
    this.getListDetails('Active');
  }

  jobLists: any = [];
  rows: number = 10;
  IjpJobStatus: number = 0;
  token: any;
  data: any;
  selectedPage: any;
  selectedTab: any;
  totalCount: any;
  IJPAll: boolean = true;
  IJPActive: boolean = false;
  IJPApproved: boolean = false;
  IJPDrafts: boolean = false;
  IJPPending: boolean = false;
  IJPRejected: boolean = false;
  noTabData: boolean = false;
  acceptRejectModal: boolean = false;
  disableNext: boolean = false;
  NoOfObjectsPerPage: number = 0;
  loading: boolean = true;
  getListDetails(type: any) {
    if (type != undefined) {
      this.jobLists = [];
      this.selectedTab = type;
      if (type == 'Active') {
        this.IjpJobStatus = 96;
        this.IJPAll = false;
        this.IJPActive = true;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = false;
        this.noTabData = false;
        this.acceptRejectModal = false;
      }
      this.NoOfObjectsPerPage = this.rows;
      console.log('type', type);
      var obj = {
        userId: this.UserId,
        type: this.type,
        pageNumber: this.selectedPage,
        numberOfObjectsPerPage: this.NoOfObjectsPerPage,
        searchItem: null,
      };

      this.IJPService.GetIJP_ReferralJobDetails(obj).subscribe((res: any) => {
        this.jobInfo = res;
          // this.IJPService.GetAllJobId(this.IjpJobStatus, this.UserId, this.selectedPage, this.NoOfObjectsPerPage).subscribe((res: any) => {
        if (res.length > 0) {
          this.loading = false;
          this.jobLists = res;
          this.totalCount = res[0].totalCount;
          if (this.totalCount >= this.NoOfObjectsPerPage) {
            this.disableNext = false;
          } else {
            this.disableNext = true;
          }
        } else {
          this.loading = false;
        }
      });
    }
    // console.log(this.jobInfo, "kkkkkkkkkkkkkkkkkkkkk");
  }

  publishid: any;
  postDates: any = [];
  publishstartdate: any;
  publishenddate: any;
  ijpJobPostedData1: any = [];
  postjobto: any;
  selectedates: any = [];
  getDate(id: any) {
    this.postDates = [];
    for (let n = 0; n < this.jobInfo.length; n++) {

      if (this.jobInfo[n].ijpBasicInformationId == id) {
        this.publishid = this.jobInfo[n].ijpBasicInformationId;
        this.ijpJobPostedData1 = this.jobInfo[n].ijpJobPostedData;
        console.log(this.ijpJobPostedData1, '===s');

        for (let i = 0; i < this.ijpJobPostedData1.length; i++) {
          var obj = {
            publishstartdate: new Date(
              this.ijpJobPostedData1[i].publishstartdate
            ),
            publishenddate: new Date(this.ijpJobPostedData1[i].publishenddate),
            postJobStatus: this.ijpJobPostedData1[i].postJobStatus,
            postjobto: this.ijpJobPostedData1[i].postjobto,
            brand_IJPJobId: this.jobInfo[n].brand_IJPJobId,
            jobTitleName: this.jobInfo[n].jobTitleName,
            id: this.ijpJobPostedData1[i].id,
            ijpBasicInformationId: this.jobInfo[n].ijpBasicInformationId,
          };
          this.postDates.push(obj);
        }
        console.log(this.postDates, '==this.postDates');
        // if(this.ijpJobPostedData1.length>0)
        //   {
        //     for(let k=0;k<this.ijpJobPostedData1.length;k++)
        //       {
        //        if(this.ijpJobPostedData1[k].postjobto=='IJP')
        //          {
        //                this.managePublishStartDate = new Date(this.ijpJobPostedData1[k].publishstartdate)
        //                console.log(this.managePublishStartDate,'==this.managePublishStartDate')
        //                this.managePublishEndDate = new Date(this.ijpJobPostedData1[k].publishenddate)
        //                this.postjobto = this.ijpJobPostedData1[k].postjobto
        //                this.postjobtoId = this.ijpJobPostedData1[k].id
        //          }
        //       }
        //   }
      }
    }
  }

  GetSearch(
    UserId: any,
    Status: any,
    pageNO: any,
    NoOfObjectsPerPage: any,
    searchItem: any
  ): void {
    // this.IJPService.GetSearch(
    //   UserId,
    //   Status,
    //   pageNO,
    //   NoOfObjectsPerPage,
    //   searchItem
    // ).subscribe((res: any) => {

    var obj = {
      userId: this.UserId,
      type: this.type,
      pageNumber: this.selectedPage,
      numberOfObjectsPerPage: this.NoOfObjectsPerPage,
      searchItem: searchItem,
    };
    this.IJPService.GetIJP_ReferralJobDetails(obj).subscribe((res: any) => {
      this.GetSearch = res;
      console.log('getSearch', res);
    });
  }

  text: any;
  onSearchChange(
    UserId: any,
    pageNO: any,
    NoOfObjectsPerPage: any,
    searchItem: any
  ): void {
    var Status = '96';


    var obj = {
      userId: UserId,
      type: this.type,
      pageNumber: pageNO,
      numberOfObjectsPerPage: NoOfObjectsPerPage,
      searchItem: searchItem,
    };

    this.IJPService.GetIJP_ReferralJobDetails(obj).subscribe((res: any) => {
    // this.IJPService.GetSearch(
    //   UserId,
    //   Status,
    //   pageNO,
    //   NoOfObjectsPerPage,
    //   searchItem
    // ).subscribe((res: any) => {
      this.jobLists = res;

      console.log('getSearch', res);
    });
  }

  clearSearch() {
    this.searchItem = null;
    this.getListDetails(this.selectedTab);
  }

  sortColumn: string = '';
  sortDirection: boolean = true;
  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = true;
    }

    this.jobLists.sort((a: any, b: any) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let result = 0;
      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      }
      return this.sortDirection ? result : -result;
    });
  }

  qrCodes: any = [];
  async generateQrCodes() {
    this.qrCodes = [];
    for (const linkObj of this.ijpJobPostedData) {
      // const qrCode = await QRCode.toDataURL(linkObj.link);
      // this.qrCodes.push(qrCode);
      if (linkObj.postJobLink !== null) {
        const qrCode = await QRCode.toDataURL(linkObj.postJobLink);
        this.qrCodes.push(qrCode);
      } else {
        // If the link is null, push null to maintain the same number of items
        this.qrCodes.push(null);
      }
    }
  }
  downloadQRCodes(qrCode: string, fileName: string) {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = `${fileName}_qrcode.png`;
    link.click();
  }
  value: any;
  enableSave: boolean = false;
  editEndDate(i: any, type: any) {
    this.value = i;
    if (type == 'edit') {
      this.enableSave = true;
      this.enableEdit = false;
    } else {
      this.enableSave = false;
      this.enableEdit = true;
    }
  }
  updateEndDate(id: any, endDate: any) {
    var enddatevalue = moment(endDate).format('YYYY-MM-DD');
    this.IJPService.updateEndDate(id, enddatevalue.toString()).subscribe(
      (res: any) => {}
    );
  }
  disableEndDate: boolean = true;
  enableEdit: boolean = true;
  publishJob(
    postjobtoid: any,
    managePublishStartDate: any,
    managePublishEndDate: any,
    status: any,
    ijpInfoId: any
  ) {
    this.IJPService.PublishUnpublish(ijpInfoId, status, postjobtoid,'').subscribe(
      (res: any) => {
        this.getListDetails('Active');

        // window.location.reload()
      }
    );
  }

  dataType(data: string): string {
    return data.replace(/<br\s*\/?>/gi, '\n');
  }
}
